import React, { useState, useEffect } from 'react'
import { useStateValue } from '../../state/AppState'
import { API_ROOT_V1 } from '../../config/api-config'
import { Button, Image } from 'react-bootstrap'
import heart from '../../assets/images/heart.svg'

export default ({ preset, onLike, isLiked = false }) => {
  const [{ user }] = useStateValue()
  const [errorMessage, setErrorMessage] = useState('')
  const [updatingLikeStatus, setUpdatingLikeStatus] = useState(false)

  const updateLikeStatus = (e) => {
    e.preventDefault()
    setUpdatingLikeStatus(true)
  }

  useEffect(() => {
    if (!updatingLikeStatus) {
      return
    }

    const reportLikePreset = (isLiked) => {
      let action = isLiked ? 'like_preset' : 'unlike_preset'
      window.gtag('event', action, {
        'event_category': 'engagement'
      })
    }

    const likeRequest = async () => {
      const response = await fetch(`${API_ROOT_V1}/users/presets/${preset.id}/likes`, {
        method: isLiked ? 'DELETE' : 'POST',
        // mode: 'cors',
        // credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.apiKey.value}`
        }
      })
      
      setUpdatingLikeStatus(false)
      
      if (response.status > 299) {
        setErrorMessage('Unable to unlike at this time. Please try again later.')
      } else {
        reportLikePreset(!isLiked)
        onLike(!isLiked)
      }
    }

    likeRequest()
  }, [isLiked, onLike, preset.id, updatingLikeStatus, user.apiKey.value])

  return (
    user.apiKey.value && (
      <Button size="sm" className="btn" variant={isLiked ? "dark" : "outline-dark"} onClick={updateLikeStatus}>
        <Image src={heart} className="icon" /> {isLiked ? "Liked" : "Like"}
      </Button>
    )
  )
}