import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useStateValue } from '../../state/AppState'
import './SignOut.css'
import { API_ROOT_V1 } from '../../config/api-config'
import { SIGNOUT } from '../../reducers/UserReducer'
import { Container } from 'react-bootstrap'

export default () => {
  const history = useHistory()
  const [{ user }, dispatch] = useStateValue()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const sleep = async (ms) => {
      await new Promise(r => setTimeout(r, ms))
    }

    const signOut = async () => {
      setIsLoading(true)
      const response = await fetch(`${API_ROOT_V1}/logout`, {
        method: 'POST',
        // mode: 'cors',
        // credentials: 'include',
        headers: {
          'Authorization': `Bearer ${user.apiKey.value}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
  
      await sleep(2000)
      setIsLoading(false)
  
      if (response.status !== 200) {
        const responseJson = await response.json()
        setErrorMessage(responseJson.reason)
      } else {
        dispatch({
          type: SIGNOUT
        })
        history.push('/')
      }
    }
    
    user.apiKey && user.apiKey.value && signOut()
  }, [user, dispatch, history])

  return (
    <Container id="signup-container">
      { isLoading && <div className="sign-out-notif">Signing out...</div> }

      { errorMessage && errorMessage.length > 0 && <div className="error">{errorMessage}</div> }
    </Container>
  );
}