const USER_SAW_FOLLOW_POPOVER = 'user_saw_follow_popover'

export default class UserDataService {

  static setSawFollowUserPopover = () => {
    localStorage.setItem(USER_SAW_FOLLOW_POPOVER, true)
  }

  static hasSeenFollowUserPopover = () => {
    return localStorage.getItem(USER_SAW_FOLLOW_POPOVER) ?? false
  }

}
