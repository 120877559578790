import React, { useState, useEffect } from 'react'
import './PresetDetail.css'
import { useStateValue } from '../../state/AppState'
import { useParams } from 'react-router-dom'
import { API_ROOT_V1 } from '../../config/api-config'
import PresetDetailHeader from './PresetDetailHeader'
import PresetDetailBody from './PresetDetailBody'
import { Container } from 'react-bootstrap'

export default (props) => {
  let { id } = useParams()
  const [{ user }] = useStateValue()
  const [preset, setPreset] = useState({})
  const [isLoadingPreset, setIsLoadingPreset] = useState(true)
  const [downloaded, setDownloaded] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!isLoadingPreset) {
      return
    }

    const fetchPreset = async () => {
      const response = await fetch(`${API_ROOT_V1}/users/presets/${id}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.apiKey.value}`
        }
      })
      const responseJson = await response.json()
      setIsLoadingPreset(false)
      if (responseJson.error && responseJson.message) {
        setErrorMessage(responseJson.message)
      } else {
        responseJson && responseJson.plugin && (document.title = `${responseJson.plugin.manufacturer} – ${responseJson.plugin.name} Presets | Preset Swap`)
        setPreset(responseJson)
      }
    }
    
    fetchPreset()
  }, [isLoadingPreset, id, user.apiKey.value])

  useEffect(() => {
    if (!downloaded) {
      return
    }

    const reportDownloadPreset = () => {
      window.gtag('event', 'download_preset', {
        'event_category': 'engagement'
      })
    }

    const fetchDownloadUrl = async () => {
      const response = await fetch(`${API_ROOT_V1}/users/presets/${preset.id}/downloads`, {
        method: 'POST',
        body: (user && user.id) ? JSON.stringify({ id: user.id}) : null,
        headers: {
          'Accept': 'application/json'
        }
      })
      const downloadJson = await response.json()
  
      setDownloaded(false)
      reportDownloadPreset()
      if (downloadJson.url) {
        window.open(downloadJson.url, "_blank")
        preset.downloads_count += 1
        setPreset({...preset})
      } else {
        setErrorMessage("Unable to download preset at this time. Please try again later.")
      }
    }

    fetchDownloadUrl()
  }, [downloaded, preset, user])

  const onComment = (comment) => {
    if (!comment || Object.keys(comment).length === 0) {
      return
    }

    (preset.comments ?? []).splice(0, 0, comment)
    preset.comments_count = preset.comments.length
    setPreset({...preset})
  }

  const onLike = (isLiked) => {
    preset.likes_count += isLiked ? 1 : -1
    preset.is_liked = isLiked
    setPreset({...preset})
  }

  return (
    <Container>
      <PresetDetailHeader preset={preset} />
      <PresetDetailBody
        loggedInUser={user}
        preset={preset}
        onDownload={() => setDownloaded(true)}
        onLike={onLike}
        onComment={onComment} />
    </Container>
  )
}