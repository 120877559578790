import React, { useState, useEffect } from 'react'
import './Account.css'

// App State
import { useStateValue } from '../../state/AppState'

// UI
import { Container, Row, Col } from 'react-bootstrap'
import UserData from './UserData'
import UserPresets from './UserPresets'
import AccountHeader from './AccountHeader'

import { API_ROOT_V1 } from '../../config/api-config'
import { RECEIVE_SINGLE_USER } from '../../reducers/UserReducer'
import UserUrlKeyForm from './UserUrlKeyForm'
import UserCookieManagement from './UserCookieManagement'
import UserLikes from './UserLikes'
import UserCommPrefsForm from './UserCommPrefsForm'

export default () => {
  const [{ user }, dispatch] = useStateValue()
  const [isLoadingUserData, setIsLoadingUserData] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!isLoadingUserData) {
      return
    }

    const fetchUserData = async () => {
      const response = await fetch(`${API_ROOT_V1}/users/${user.id}`, {
        method: 'GET',
        // mode: 'cors',
        // credentials: 'include',
        headers: {
          'Authorization': `Bearer ${user.apiKey.value}`,
          'Accept': 'application/json'
        }
      })
      const responseJson = await response.json()
      if (responseJson.error && responseJson.reason) {
        setIsLoadingUserData(false)
        setErrorMessage(responseJson.reason)
        return
      }
  
      const presetsResponse = await fetch(`${API_ROOT_V1}/users/presets?per=25`, {
        method: 'GET',
        // mode: 'cors',
        // credentials: 'include',
        headers: {
          'Authorization': `Bearer ${user.apiKey.value}`,
          'Accept': 'application/json'
        }
      })
      const presetsResponseJson = await presetsResponse.json()
      setIsLoadingUserData(false)
      if (presetsResponseJson.error && presetsResponseJson.reason) {
        setErrorMessage(presetsResponseJson.reason)
        return
      }
  
      dispatch({
        type: RECEIVE_SINGLE_USER,
        user: responseJson,
        presets: presetsResponseJson
      })
    }

    fetchUserData()
  })

  return (
    <Container>
      <div className="account-container">
        <AccountHeader />

        <div className="body-wrapper">
          <Container>
            <Row>
              <Col lg={true}>
                <UserUrlKeyForm />
                <UserData />
                <UserCommPrefsForm />
                <UserCookieManagement />
              </Col>
              <Col lg={true}>
                <UserPresets />
                <UserLikes user={user} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Container>
  )
}