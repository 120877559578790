import React, { useState, useEffect } from 'react'
import './UploadProfilePicModal.css'
import { useStateValue } from '../../state/AppState'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import FormData from 'form-data'
import { API_ROOT_V1 } from '../../config/api-config'
import { RECEIVE_USER_AVATAR } from '../../reducers/UserReducer'

const initialFormState = { file: '' }

export default ({ show, onHide }) => {
  const [{ user }, dispatch] = useStateValue()
  const [formEvent, setFormEvent] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!isLoading || Object.keys(formEvent).length === 0) {
      return
    }

    const uploadFile = async () => {
      const form = new FormData()
      const files = formEvent.target.files
      form.append('file', files[0])
      let json
      try {
        const response = await fetch(`${API_ROOT_V1}/users/avatars/`, {
          method: 'POST',
          // mode: 'cors',
          headers: {
            'Authorization': `Bearer ${user.apiKey.value}`,
            'Accept': 'application/json'
          },
          body: form
        })
        json = await response.json()
      } catch (error) {
        console.log('Caught error in UploadProfilePicModal', error);
      } finally {
        setIsLoading(false)
      }
      
      if (json && json.error && json.reason) {
        setErrorMessage(json.reason)
      } else {
        onHide()
        dispatch({
          type: RECEIVE_USER_AVATAR,
          avatar: json
        })
      }
    }

    uploadFile()
  }, [isLoading, dispatch, formEvent, user.apiKey.value, onHide])

  const schema = yup.object({
    file: yup.string().required()
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={() => {
        setErrorMessage('')
        setIsLoading(true)
      }}
      initialValues={initialFormState}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Modal show={show} onHide={onHide} className="profile-pic-upload-modal" animation={true}>
          <Modal.Header closeButton>
            <Modal.Title className="modal-title">Upload a profile picture</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group>
                <Form.File
                  className="position-relative"
                  required
                  name="file"
                  label="File"
                  onChange={(e) => {
                    setFormEvent(e)
                    handleChange(e)
                  }}
                  isInvalid={!!errors.file}
                  feedback={errors.file}
                  id="file"
                  feedbackTooltip
                />
              </Form.Group>
              
              { isLoading ? 
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Loading...</span>
                </Button>
              :
                <Button type="submit">
                  Upload
                </Button> }
            </Form>

            {errorMessage && errorMessage.length > 0 && <div className="error-message">{errorMessage}</div>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  )
}