import React, { useState, useEffect } from 'react'
import './UserUrlKeyForm.css'

// App State
import { useStateValue } from '../../state/AppState'
import { UPDATE_USER } from '../../reducers/UserReducer'

// Form
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, Col, Button, Spinner, InputGroup } from 'react-bootstrap'
import { API_ROOT_V1 } from '../../config/api-config'

const schema = yup.object({
  urlKey: yup.string().required()
})

export default () => {
  const [{ user }, dispatch] = useStateValue()
  const [userFormData, setUserFormData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isValidUrlKey, setIsValidUrlKey] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const initialFormState = { urlKey: user.urlKey }

  useEffect(() => {
    if (!isLoading || Object.keys(userFormData).length === 0) {
      return
    }

    const reportSetUserUrlKey = () => {
      window.gtag('event', 'update_url_key', {
        'event_category': 'engagement',
        'event_label': 'user'
      })
    }
    
    const updateUserRequest = async () => {
      const response = await fetch(`${API_ROOT_V1}/users/${user.id}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${user.apiKey.value}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          url_key: userFormData.urlKey
        })
      })
      const responseJson = await response.json()
  
      setIsLoading(false)
      if (responseJson.error && responseJson.reason) {
        setIsValidUrlKey(false)
        setErrorMessage(responseJson.reason)
      } else {
        reportSetUserUrlKey()
        setIsValidUrlKey(true)
        dispatch({
          type: UPDATE_USER,
          user: responseJson
        })
      }
    }

    updateUserRequest()
  }, [isLoading, dispatch, user.apiKey.value, user.id, userFormData])

  return (
    <section id="userUrlKey">
      <div className="profile-title">Custom Profile URL</div>

      <Formik
        validationSchema={schema}
        onSubmit={(form) => {
          setErrorMessage('')
          setUserFormData(form)
          setIsLoading(true)
        }}
        initialValues={initialFormState}
      >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="urlKey">
              <Form.Label>Choose your custom url</Form.Label>
              <InputGroup className="mb-2 mr-sm-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>presetswap.com/users/</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="urlKey"
                  value={values.urlKey}
                  onChange={handleChange}
                  isValid={touched.urlKey && !errors.urlKey && isValidUrlKey}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>

          { errorMessage && errorMessage.length > 0 && <div className="error">{errorMessage}</div> }

          { isLoading ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="sr-only">Loading...</span>
            </Button>
           ) : (
            <Button variant="outline-primary" size="sm" type="submit">Update</Button>
           )
          }
        
        </Form>
      )}
      </Formik>
    </section>
  )
}
