import React, { useState, useEffect } from 'react'
import './UserLikes.css'

// UI
import PresetsList from '../presets/PresetsList'
import { API_ROOT_V1 } from '../../config/api-config'

export default ({ user }) => {
  const [presets, setPresets] = useState({items: []})
  const [isLoadingPresets, setIsLoadingPresets] = useState(true)

  useEffect(() => {
    if (!isLoadingPresets) {
      return
    }
    const fetchPresets = async () => {
      let presets = []
      try {
        const response = await fetch(`${API_ROOT_V1}/users/${user.id}/likes`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${user.apiKey.value}`
          }
        })
        const likes = await response.json()
        console.log('likes', likes);
        presets = likes.items?.map((like) => like.preset)
        console.log('presets', presets);
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoadingPresets(false)
      }
      setPresets({items: presets})
    }

    fetchPresets()
  }, [isLoadingPresets, user.apiKey.value, user.id, user.urlKey])

  return (
    <section>
      <div className="presets-title padding-bottom">Liked Presets</div>

      <PresetsList presets={presets} showAds={false} />
    </section>
  )
}
