import React, { useEffect } from 'react'
import { ListGroupItem } from 'react-bootstrap'

export default () => {

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (error) {
      console.log(error)
    }
  })

  return (
    <ListGroupItem>
      <ins className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-format="fluid"
        data-ad-layout-key="-hz-4+2b-1w-4a"
        data-ad-client="ca-pub-4512130639300137"
        data-ad-slot="5015607129"></ins>
    </ListGroupItem>
  )
}