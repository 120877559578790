import React from 'react'
import './PresetDetailHeader.css'
import PluginImage from '../plugins/PluginImage'
import { Container, Row, Col } from 'react-bootstrap'

export default ({ preset }) => {
  return (
    <Container className="preset-header">
      <Row className="text-shadow">
        <Col xl={3} lg={4} md={5} sm={1} xs={1}>
          { preset.plugin && <PluginImage plugin={preset.plugin} /> }
        </Col>
        <Col>
          <div>
            <h1>{ preset.name }</h1>
            <h3>{ preset.plugin && `${preset.plugin.manufacturer} – ${preset.plugin.name}` }</h3>
          </div>
        </Col>
      </Row>
    </Container>
  )
}