import React from 'react'
import './AccountImage.css'
import { Image, OverlayTrigger } from 'react-bootstrap'

export default ({ user, onClick, overlay, picSizeClass = '', placeholderSizeClass = '' }) => {
  return (
    <>
      {(user.avatar && user.avatar.url) ? (
        <Image src={user.avatar.url} className={`account-profile-pic ${picSizeClass}`} roundedCircle onClick={onClick} />
      ) : (
        ( overlay ? (
          <OverlayTrigger
            placement="top"
            delay={{ hide: 400 }}
            overlay={overlay}>
            <div className={`account-profile-placeholder ${placeholderSizeClass}`}  onClick={onClick}>
              {(user.firstName || '').slice(0, 1)}{(user.lastName || '').slice(0, 1)}
            </div>
          </OverlayTrigger> 
        ) : (
          <div className={`account-profile-placeholder ${placeholderSizeClass}`} onClick={onClick}>
            {(user.firstName || user.first_name || '').slice(0, 1)}{(user.lastName || user.last_name || '').slice(0, 1)}
          </div>
        ))
      )}
    </>
  )
}