import React, { useState, useEffect } from 'react'
import './PresetUploadForm.css'
import { useHistory } from 'react-router'
import { useStateValue } from '../../state/AppState'
import { Container, Button, Form, InputGroup, Col, Spinner } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import FormData from 'form-data'
import { API_ROOT_V1 } from '../../config/api-config'
import { RECEIVE_PLUGINS } from '../../reducers/PluginReducer'
import { RECEIVE_USER_PRESET } from '../../reducers/UserReducer'
import PluginOption from '../plugins/PluginOption'
import AccountHeader from '../account/AccountHeader'

const initialFormState = { 
  name: '',
  notes: '',
  pluginId: '',
  file: '',
  youTubeVideo: '',
  soundCloudEmbed: ''
}

const schema = yup.object({
  pluginIn: yup.string().required(),
  name: yup.string().required(),
  notes: yup.string().required(),
  file: yup.string().required(),
  youTubeVideo: yup.string().optional(),
  soundCloudEmbed: yup.string().optional()
})

export default () => {
  const [{ user, plugins }, dispatch] = useStateValue()
  const history = useHistory()
  const [presetFormData, setPresetFormData] = useState({})
  const [formFileEvent, setFormFileEvent] = useState({})
  const [isSavingPreset, setIsSavingPreset] = useState(false)
  const [isLoadingPlugins, setIsLoadingPlugins] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!isSavingPreset || Object.keys(presetFormData).length === 0 || Object.keys(formFileEvent).length === 0) {
      return
    }

    const reportUploadPreset = () => {
      window.gtag('event', 'upload_preset', {
        'event_category': 'engagement'
      })
    }

    const createPreset = async () => {
      const form = new FormData()
      const files = formFileEvent.target.files
      form.append('file', files[0])
      form.append('name', presetFormData.name)
      form.append('notes', presetFormData.notes)
      console.log("presetFormData.youTubeVideo", presetFormData.youTubeVideo);
      form.append('youtube_video_url', presetFormData.youTubeVideo)
      form.append('soundcloud_embed_code', presetFormData.soundCloudEmbed)
      const pluginSelect = document.getElementById('pluginIn')
      const pluginId = pluginSelect.options[pluginSelect.selectedIndex].getAttribute('id')
      form.append('plugin_id', pluginId)
      
      const response = await fetch(`${API_ROOT_V1}/users/presets`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${user.apiKey.value}`,
          'Accept': 'application/json'
        },
        body: form
      })
      const responseJson = await response.json()
  
      setIsSavingPreset(false)
      if (responseJson.error && responseJson.reason) {
        console.log("responseJson.error", responseJson.error);
        setErrorMessage(responseJson.reason)
      } else {
        reportUploadPreset()
        dispatch({
          type: RECEIVE_USER_PRESET,
          preset: responseJson
        })
        history.push('/account')
      }
    }

    createPreset()
  }, [isSavingPreset, dispatch, formFileEvent, presetFormData, user.apiKey.value, history])

  useEffect(() => {
    if (!isLoadingPlugins) {
      return
    }

    const fetchPlugins = async () => {
      const response = await fetch(`${API_ROOT_V1}/plugins`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      })
      const responseJson = await response.json()
      if (responseJson.error && responseJson.reason) {
        setErrorMessage(responseJson.reason)
      } else {
        setIsLoadingPlugins(false)
        dispatch({
          type: RECEIVE_PLUGINS,
          plugins: responseJson
        })
      }
    }

    fetchPlugins()
  }, [isLoadingPlugins, dispatch])

  return (
    <Container>
      <AccountHeader />

      <div className="account-details body-wrapper">
        <h2>Upload a Preset</h2>

        <Formik
          validationSchema={schema}
          onSubmit={(form) => {
            setErrorMessage('')
            setPresetFormData(form)
            setIsSavingPreset(true)
          }}
          initialValues={initialFormState}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} controlId="name">
                  <Form.Label>Preset Name</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Preset Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="notes">
                  <Form.Label>Notes</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="Enter any details that may be important to know about this preset..."
                      name="notes"
                      value={values.notes}
                      onChange={handleChange}
                      isInvalid={!!errors.notes}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.notes}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="youTubeVideo">
                  <Form.Label>YouTube Video (optional)</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="YouTube Demo Video Link..."
                      name="youTubeVideo"
                      value={values.youTubeVideo}
                      onChange={handleChange}
                      isInvalid={!!errors.youTubeVideo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.youTubeVideo}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="soundCloudEmbed">
                  <Form.Label>SoundCloud Audio Sample (optional)</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="SoundCloud Embed Code e.g. <iframe ...>"
                      name="soundCloudEmbed"
                      value={values.soundCloudEmbed}
                      onChange={handleChange}
                      isInvalid={!!errors.soundCloudEmbed}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.soundCloudEmbed}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="pluginIn">
                  <Form.Label>Plugin</Form.Label>
                  <Form.Control 
                    as="select"
                    name="pluginIn"
                    onChange={handleChange}
                    isInvalid={!!errors.pluginIn}
                    defaultValue="Choose..."
                  >
                    {
                      [{id: 'default', manufacturer: 'Choose...', name: ''}]
                        .concat(plugins)
                        .map((plugin) => <PluginOption key={plugin.id} {...plugin} />)
                    }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.pluginIn}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="file">
                  <Form.File
                    className="position-relative"
                    required
                    name="file"
                    label="File"
                    onChange={(e) => {
                      setFormFileEvent(e)
                      handleChange(e)
                    }}
                    isInvalid={!!errors.file}
                    feedback={errors.file}
                    id="file"
                    feedbackTooltip
                  />
                </Form.Group>
              </Form.Row>

              {errorMessage && errorMessage.length > 0 && <div className="error">{errorMessage}</div>}

              { isSavingPreset ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                  </Button>
                ) : (
                  <Button type="submit">Submit</Button>
                )
              }
            
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  )
}