import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useStateValue } from '../../state/AppState'
import SignUpForm from './SignUpForm'
import './SignUp.css'

export default () => {
  const history = useHistory()
  const [{ user }] = useStateValue()

  useEffect(() => {
    user.apiKey && user.apiKey.value && history.push('/account')
  }, [user, history])

  return (
    <div id="signup-container">
      <SignUpForm />
    </div>
  );
}