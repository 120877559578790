import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './Footer.css'
import { Link } from 'react-router-dom'

export default (props) => {

  const contactUs = (e) => { 
    e.preventDefault()
    window.location.href = 'mailto:contact@presetswap.com'
  }

  return (
    <div id="footer">
      <Container>
        <Row>
          <Col lg={true}>
            © PresetSwap, LLC 2020
          </Col>
          <Col className="right-align" lg={true}>
            {/* <Link to="/donate">Donate</Link><br/><br/> */}
            <Link to='' onClick={contactUs}>Contact Us</Link><br/><br/>
            <a href="/terms">Terms of Service</a> & <a href="/privacy">Privacy Policy</a>
          </Col>
        </Row>
      </Container>
    </div>
  )
}