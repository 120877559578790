import React, { useState, useEffect } from 'react'
import './SignUpForm.css'
import { Form, Col, InputGroup, Button, Image, Spinner } from 'react-bootstrap'
import { API_ROOT_V1 } from '../../config/api-config'
import ReCAPTCHA from "react-google-recaptcha"

// App State
import { useStateValue } from '../../state/AppState'
import { RECEIVE_SINGLE_USER } from '../../reducers/UserReducer'

// Form
import { Formik } from 'formik'
import { object, string } from 'yup'

// Images
import guitar from '../../assets/images/guitar.jpg'
import { Link } from 'react-router-dom'


const schema = object({
  firstName: string().required(),
  lastName: string().required(),
  email: string().required(),
  password: string().required(),
  terms: string().required(),
});

const initialFormState = { firstName: '', lastName: '', email: '', password: '', terms: '' }

export default () => {
  const [{}, dispatch] = useStateValue()
  const [formData, setFormData] = useState(initialFormState)
  const [isLoading, setIsLoading] = useState(false)
  const [isCaptchaValidated, setIsCaptchaValidated] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!isLoading || formData === initialFormState) {
      return
    }

    if (!isCaptchaValidated) {
      setIsLoading(false)
      setErrorMessage("Please complete all required form fields and try again.")
      return
    }

    const body = {
      email: formData.email,
      password: formData.password,
      confirm_password: formData.password,
      first_name: formData.firstName,
      last_name: formData.lastName
    }

    const reportConversion = (callback) => {
      window.gtag('event', 'sign_up')
      window.gtag('event', 'conversion', {
          'send_to': 'AW-1060100461/cygxCMHyqtwBEO2yv_kD',
          'event_callback': callback
      })
    }

    const fetchData = async () => {
      const response = await fetch(`${API_ROOT_V1}/users`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      const responseJson = await response.json()

      setIsLoading(false)
      if (responseJson.error && responseJson.reason) {
        setErrorMessage(responseJson.reason)
      } else {
        reportConversion(() => {
          dispatch({
            type: RECEIVE_SINGLE_USER,
            user: responseJson
          })
        })
      }
    }

    fetchData()
  }, [isLoading, dispatch, formData, isCaptchaValidated])

  const captchaOnChange = (value) => {
    if (value) setIsCaptchaValidated(true)
  }

  // Track page view conversion
  window.gtag('event', 'conversion', {'send_to': 'AW-1060100461/cIjaCIG6wtwBEO2yv_kD'});

  return (
    <div id="signupform-container">
      <Image 
        className="signup-photo"
        roundedCircle
        src={guitar} />
      <Formik
        validationSchema={schema}
        onSubmit={(form) => {
          setErrorMessage('')
          setFormData(form)
          setIsLoading(true)
        }}
        initialValues={initialFormState}
      >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="firstName">
              <Form.Label>First name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                placeholder="First name"
                value={values.firstName}
                onChange={handleChange}
                isValid={touched.firstName && !errors.firstName}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="lastName">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                placeholder="Last name"
                value={values.lastName}
                onChange={handleChange}
                isValid={touched.lastName && !errors.lastName}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="email">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="password">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Group>
            <Form.Check
              required
              name="terms"
              label={<a href="/terms">I agree to the Terms of Service</a>}
              onChange={handleChange}
              isInvalid={!!errors.terms}
              feedback={errors.terms}
              id="terms"
            />
          </Form.Group>

          {errorMessage && errorMessage.length > 0 && <div className="error">{errorMessage}</div>}

          { isLoading ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="sr-only">Loading...</span>
            </Button>
           ) : (
            <>
              <ReCAPTCHA
                sitekey="6Ley7HIcAAAAAHSLKTWXxbypSHNqJ3mogFFSNzON"
                onChange={captchaOnChange}
              />
              <Button variant="light" type="submit">Sign up</Button>
            </>
           )
          }
        
        </Form>
      )}
      </Formik>

      <div className="no-account">
        Already have an account? <Link to="/signin">Sign in!</Link>
      </div>
    </div>
  );
}