import React from 'react'
import './Header.css'
import { useStateValue } from '../../state/AppState'
import { Nav, Navbar, Container, Image, NavDropdown, Button } from 'react-bootstrap'

export default (props) => {
  const [{ user }] = useStateValue()

  return (
    <Navbar collapseOnSelect expand="lg" variant="dark" fixed="top" className="shadow">
      <Container>
        <Navbar.Brand href="/">{ props.brand }</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            { props.links }
          </Nav>
          { user.fullName && user.fullName.length > 0 && (
              <Nav>
                <NavDropdown id="profile" alignRight title={user.avatar ? (
                    <Image className="profile-pic" src={user.avatar.url} roundedCircle />
                  ) : (
                    <div className="no-profile-pic">
                      {(user.firstName || '').slice(0, 1)}{(user.lastName || '').slice(0, 1)}
                    </div>
                  )
                }>
                  <NavDropdown.Item href="/account">{user.fullName}</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/uploadpreset">Upload Preset</NavDropdown.Item>
                  {/* <NavDropdown.Item href="/mypresets">My Presets</NavDropdown.Item> */}
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/signout">Sign out</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            )
          }
          { (!user || !user.fullName) && (
              <Nav variant="pills">
                <Nav.Item>
                  <Button size="sm" className="cta-button-nav" href="/signup">Sign up</Button>
                </Nav.Item>
              </Nav>
            )
          }
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

