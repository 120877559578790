import React, { useState, useEffect } from 'react'
import { useStateValue } from '../../state/AppState'
import { API_ROOT_V1 } from '../../config/api-config'
import { Button } from 'react-bootstrap'

export default ({ user, onFollow, btnId = 'follow-btn' }) => {
  const [{ user: loggedInUser }] = useStateValue()
  const [errorMessage, setErrorMessage] = useState('')
  const [updatingFollowStatus, setUpdatingFollowStatus] = useState(false)
  const [updatingFollowStatusEvent, setUpdatingFollowStatusEvent] = useState(null)

  const updateFollowStatus = (e) => {
    e.preventDefault()
    setUpdatingFollowStatus(true)
    setUpdatingFollowStatusEvent(e)
  }

  useEffect(() => {
    if (!updatingFollowStatus) {
      return
    }

    const reportFollowUser = (isFollowed) => {
      let action = isFollowed ? 'follow_user' : 'unfollow_user'
      window.gtag('event', action, {
        'event_category': 'engagement'
      })
    }

    const followRequest = async () => {
      const response = await fetch(`${API_ROOT_V1}/users/${user.id}/followers`, {
        method: user.is_followed ? 'DELETE' : 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${loggedInUser.apiKey.value}`
        }
      })

      setUpdatingFollowStatus(false)
      
      if (response.status > 299) {
        setErrorMessage('Unable to unfollow at this time. Please try again later.')
      } else {
        reportFollowUser(!user.is_followed)
        onFollow(!user.is_followed, updatingFollowStatusEvent)
      }
      setUpdatingFollowStatusEvent(null)
    }

    followRequest()
  }, [user.is_followed, onFollow, user.id, updatingFollowStatus, loggedInUser.apiKey.value])

  return (
    loggedInUser.apiKey.value && (
      <Button id={btnId} size="sm" className="btn cta-button" variant={user.is_followed ? "dark" : "outline-dark"} onClick={updateFollowStatus}>
        { user.is_followed ? "Unfollow" : "Follow" }
      </Button>
    )
  )
}