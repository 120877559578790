import React, { useState, useEffect } from 'react'
import './PresetUploadForm.css'
import { useHistory, useParams } from 'react-router-dom'
import { useStateValue } from '../../state/AppState'
import { Container, Button, Form, InputGroup, Col, Spinner } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup'
import { API_ROOT_V1 } from '../../config/api-config'
import { RECEIVE_USER_PRESET } from '../../reducers/UserReducer'
import AccountHeader from '../account/AccountHeader'

const initialFormState = { 
  name: '',
  notes: '',
  youTubeVideo: '',
  soundCloudEmbed: ''
}

const schema = yup.object({
  name: yup.string().optional(),
  notes: yup.string().optional(),
  youTubeVideo: yup.string().optional(),
  soundCloudEmbed: yup.string().optional()
})

export default () => {
  let { id } = useParams()
  const [{ user }, dispatch] = useStateValue()
  const history = useHistory()
  const [preset, setPreset] = useState({})
  const [isLoadingPreset, setIsLoadingPreset] = useState(true)
  const [presetFormData, setPresetFormData] = useState({})
  const [isSavingPreset, setIsSavingPreset] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!isLoadingPreset) {
      return
    }

    const fetchPreset = async () => {
      const response = await fetch(`${API_ROOT_V1}/users/presets/${id}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.apiKey.value}`
        }
      })
      const responseJson = await response.json()
      setIsLoadingPreset(false)
      if (responseJson.error && responseJson.message) {
        setErrorMessage(responseJson.message)
      } else {
        setPreset(responseJson)
        
        initialFormState.name = responseJson.name
        document.getElementById("name").value = responseJson.name

        initialFormState.notes = responseJson.notes
        document.getElementById("notes").value = responseJson.notes
        
        if (responseJson.youtube_id) {
          initialFormState.youTubeVideo = `https://www.youtube.com/watch?v=${responseJson.youtube_id}`
          document.getElementById("youTubeVideo").value = `https://www.youtube.com/watch?v=${responseJson.youtube_id}`
        }
        
        if (responseJson.soundcloud_id) {
          // initialFormState.soundCloudEmbed = responseJson.youtube_id
          // document.getElementById("soundCloudEmbed").value = responseJson.soundcloud_id
        }
      }
    }
    
    fetchPreset()
  }, [isLoadingPreset, id, user.apiKey.value])

  useEffect(() => {
    if (!isSavingPreset || Object.keys(presetFormData).length === 0) {
      return
    }

    const reportUpdatePreset = () => {
      window.gtag('event', 'update_preset', {
        'event_category': 'engagement'
      })
    }

    const updatePreset = async () => {      
      const response = await fetch(`${API_ROOT_V1}/users/presets/${preset.url_key}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.apiKey.value}`,
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          name: presetFormData.name,
          notes: presetFormData.notes,
          youtube_video_url: presetFormData.youTubeVideo,
          soundcloud_embed_code: presetFormData.soundCloudEmbed
        })
      })
      const responseJson = await response.json()
  
      setIsSavingPreset(false)
      if (responseJson.error && responseJson.reason) {
        console.log("responseJson.error", responseJson.error);
        setErrorMessage(responseJson.reason)
      } else {
        reportUpdatePreset()
        dispatch({
          type: RECEIVE_USER_PRESET,
          preset: responseJson
        })
        history.push('/account')
      }
    }

    updatePreset()
  }, [dispatch, history, isSavingPreset, preset.url_key, presetFormData, user.apiKey.value])

  return (
    <Container>
      <AccountHeader />

      <div className="account-details body-wrapper">
        <h2>Update Preset</h2>

        <Formik
          validationSchema={schema}
          onSubmit={(form) => {
            setErrorMessage('')
            setPresetFormData(form)
            setIsSavingPreset(true)
          }}
          initialValues={initialFormState}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} controlId="name">
                  <Form.Label>Preset Name</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Preset Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="notes">
                  <Form.Label>Notes</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="Enter any details that may be important to know about this preset..."
                      name="notes"
                      value={values.notes}
                      onChange={handleChange}
                      isInvalid={!!errors.notes}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.notes}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="youTubeVideo">
                  <Form.Label>YouTube Video</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="YouTube Demo Video Link..."
                      name="youTubeVideo"
                      value={values.youTubeVideo}
                      onChange={handleChange}
                      isInvalid={!!errors.youTubeVideo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.youTubeVideo}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="soundCloudEmbed">
                  <Form.Label>SoundCloud Audio Sample</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="SoundCloud Embed Code e.g. <iframe ...>"
                      name="soundCloudEmbed"
                      value={values.soundCloudEmbed}
                      onChange={handleChange}
                      isInvalid={!!errors.soundCloudEmbed}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.soundCloudEmbed}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>

              {errorMessage && errorMessage.length > 0 && <div className="error">{errorMessage}</div>}

              { isSavingPreset ? (
                  <Button variant="primary" disabled>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                  </Button>
                ) : (
                  <Button type="submit">Submit</Button>
                )
              }
            
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  )
}