import React, { useEffect } from 'react'

export default () => {

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (error) {
      console.log(error)
    }
  })

  return (
    <ins className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client="ca-pub-4512130639300137"
      data-ad-slot="5585126377"
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>
  )
}