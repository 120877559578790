import React, {createContext, useContext, useReducer} from 'react'
import AuthService from '../services/auth/AuthService'

export const StateContext = createContext()

export const initialAppState = {
  user: AuthService.authedAcct() || {
    apiKey: {
      value: ''
    },
    avatar: {
      id: '',
      url: ''
    },
    id: '',
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    presets: {
      items: [],
      metadata: {
        page: 0,
        per: 0,
        total: 0
      }
    },
    communicationPrefs: {
      items: [],
      metadata: {
        page: 0,
        per: 0,
        total: 0
      }
    },
    createdAt: '',
    updatedAt: '',
    is_followed: false,
  },
  plugins: []
}

export const StateProvider = ({reducer, initialState, children}) =>(
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)

export const useStateValue = () => useContext(StateContext)