import React from "react"
import { Route } from "react-router-dom"
import PrivateRoute from './PrivateRoute'

export default route => {
  return route.private ? (
    <PrivateRoute
      path={route.path}
      exact={route.exact}
      component={route.component}
      render={props => <route.component {...props} /> }
    />
  ) : (
    <Route
      path={route.path}
      exact={route.exact}
      render={props => <route.component {...props} /> }
    />
  )
}
