import Home from "../components/home/Home"
import Presets from "../components/presets/Presets"
import SignIn from "../components/signin/SignIn"
import SignUp from "../components/signup/SignUp"
import SignOut from "../components/signout/SignOut"
import Account from "../components/account/Account"
import PresetUploadForm from '../components/presets/PresetUploadForm'
import PresetDetail from "../components/presets/PresetDetail"
import Terms from "../components/terms/Terms"
import UserDetail from "../components/account/UserDetail"
import Privacy from "../components/privacy/Privacy"
import PresetUpdateForm from "../components/presets/PresetUpdateForm"

export default [
  {
    path: "/",
    component: Home,
    exact: true
  },
  {
    path: "/presets",
    component: Presets,
    exact: true
  },
  {
    path: "/presets/:id",
    component: PresetDetail,
    // exact: true
  },
  {
    path: "/users/:id",
    component: UserDetail,
    // exact: true
  },
  {
    path: "/account",
    component: Account,
    private: true,
    // exact: true
  },
  {
    path: "/uploadpreset",
    component: PresetUploadForm,
    private: true,
    // exact: true
  },
  {
    path: "/updatepreset/:id",
    component: PresetUpdateForm,
    private: true,
    // exact: true
  },
  {
    path: "/signin",
    component: SignIn,
    // exact: true
  },
  {
    path: "/signout",
    component: SignOut,
    private: true,
    // exact: true
  },
  {
    path: "/signup",
    component: SignUp,
    // exact: true
  },
  {
    path: "/terms",
    component: Terms,
    // exact: true
  },
  {
    path: "/privacy",
    component: Privacy,
    // exact: true
  }
]
