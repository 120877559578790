import React, { useEffect, useState } from 'react'
import './PresetSearchHeader.css'
import { useStateValue } from '../../state/AppState'
import { Form, Col, Button, InputGroup } from 'react-bootstrap'
import { API_ROOT_V1 } from '../../config/api-config'
import { RECEIVE_PLUGINS } from '../../reducers/PluginReducer'
import PluginOption from '../plugins/PluginOption'

export default ({ onSearch }) => {
  const [{ plugins }, dispatch] = useStateValue()
  const [pluginsLoaded, setPluginsLoaded] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedPlugin, setSelectedPlugin] = useState({})

  useEffect(() => {
    if (pluginsLoaded) {
      return
    }

    const fetchPlugins = async () => {
      const response = await fetch(`${API_ROOT_V1}/plugins`, {
        method: 'GET',
        // mode: 'cors',
        // credentials: 'include',
        headers: {
          'Accept': 'application/json'
        }
      })
      const responseJson = await response.json()
      if (responseJson.error && responseJson.reason) {
        setErrorMessage(responseJson.reason)
      } else {
        setPluginsLoaded(true)
        dispatch({
          type: RECEIVE_PLUGINS,
          plugins: responseJson
        })
      }
    }

    fetchPlugins()
  }, [pluginsLoaded, dispatch])

  const handleSubmit = (e) => {
    e.preventDefault()
    const searchText = document.getElementById('presetSearchText').value
    console.log('searchText', searchText);
    onSearch({
      text: searchText
    })
  }

  const handlePluginSelection = (e) => {
    const pluginSelect = e.target
    const plugin = pluginSelect.options[pluginSelect.selectedIndex]
    onSearch({
      plugin: plugin.value,
    })
  }
  
  return (
    <div className="presets-header">
      <h1>Search Presets</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Row className="align-items-center">
          <Col>
            <Form.Group>
              <Form.Label htmlFor="presetSearchText" srOnly>
                Search Presets
              </Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <span role="img" aria-label="search">🔍</span>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control 
                  id="presetSearchText"
                  size="lg"
                  type="text"
                  placeholder="Search presets by name"
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col>
            <Button variant="primary" type="submit" size="lg" className="cta-button">
              Submit
            </Button>
          </Col>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label htmlFor="pluginId">
              <h2>Filter by Plugin</h2>
            </Form.Label>
            <Form.Control 
              as="select"
              size="lg"
              id="pluginId"
              onChange={handlePluginSelection}
              defaultValue="Choose..."
            >
              {
                [{id: 'default', manufacturer: 'Choose...', name: ''}]
                  .concat(plugins)
                  .map((plugin) => <PluginOption key={plugin.id} {...plugin} />)
              }
            </Form.Control>
          </Form.Group>
        </Form.Row>
      </Form>
    </div>
  )
}