import React from 'react'
import { Nav } from 'react-bootstrap'
import './PresetsNavLink.css'
import { LinkContainer } from 'react-router-bootstrap'

export default () => {
  return (
    <LinkContainer to="/presets">
      <Nav.Link eventKey="/presets">Presets</Nav.Link>
    </LinkContainer>
  )
}
