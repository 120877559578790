import React from 'react'
import './Terms.css'
import { Container } from 'react-bootstrap'

export default () => {
  return (
    <Container>
      <div name="termly-embed"
        data-id="a0491817-e499-4dd8-91c9-f0b735354ff5"
        data-type="iframe"></div>

      { (function(d, s, id) {
          var js, tjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = "https://app.termly.io/embed-policy.min.js";
          tjs.parentNode.insertBefore(js, tjs);
        }(document, 'script', 'termly-jssdk')) }
    </Container>
  )
}