import React from 'react'
import { Button } from 'react-bootstrap'

export default () => {
  return (
    <section>
      <div className="profile-title">Cookie Management</div>
      <Button 
        variant="outline-dark"
        size="sm"
        className="termly-cookie-preference-button" 
        onClick={() => {window.displayPreferenceModal()}}>
        Manage Cookie Preferences
      </Button>
    </section>
  )
}