import React from 'react'
import './UserPresets.css'

// UI
import PresetsList from '../presets/PresetsList'
import { Button } from 'react-bootstrap'

export default () => {
  return (
    <section>
      <div className="presets-title padding-bottom">Presets</div>

      <Button 
        size="sm"
        variant="outline-primary"
        className="margin-bottom"
        href="/uploadpreset"
      >
        Upload a Preset
      </Button>

      <PresetsList canDelete={true} canEdit={true} showAds={false} />
    </section>
  )
}
