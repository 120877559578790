export const RECEIVE_PLUGINS = 'RECEIVE_PLUGINS'

export default (state, action) => {
  // console.log('pluginReducer::state', state)
  // console.log('pluginReducer::action', action)

  let newState

  switch (action.type) {
    case RECEIVE_PLUGINS:
      // console.log('RECEIVE_PLUGINS')
      const plugins = action.plugins ?? state ?? []
      newState = plugins
      break
    default:
      newState = state
  }

  // console.log('pluginReducer::newState', newState)

  return newState
}
