import React, { useState, useEffect } from 'react'
import './UserDetail.css'
import { useStateValue } from '../../state/AppState'
import { useParams } from 'react-router-dom'
import { API_ROOT_V1 } from '../../config/api-config'
import { Container } from 'react-bootstrap'
import UserDetailHeader from './UserDetailHeader'
import PresetsList from '../presets/PresetsList'

export default (props) => {
  let { id } = useParams()
  const [{ user }] = useStateValue()
  const [userAcct, setUserAcct] = useState({presets: []})
  const [isLoadingUserAcct, setIsLoadingUserAcct] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const onFollow = (isFollowed) => {
    console.log('onFollow:isFollowed', isFollowed);
    const updatedUserAcct = { 
      ...userAcct,
      is_followed: isFollowed
    }
    setUserAcct(updatedUserAcct)
  }

  useEffect(() => {
    if (!isLoadingUserAcct) {
      return
    }

    const fetchUser = async () => {
      const response = await fetch(`${API_ROOT_V1}/users/public/${id}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.apiKey.value}`
        }
      })
      const responseJson = await response.json()
      setIsLoadingUserAcct(false)
      if (responseJson.error && responseJson.message) {
        setErrorMessage(responseJson.message)
      } else {
        setUserAcct(responseJson)
      }
    }
    
    fetchUser()
  }, [isLoadingUserAcct, id])

  return (
    <Container>
      <UserDetailHeader user={userAcct} onFollow={onFollow} />
      <div className="body-wrapper">
        <section>
          <h3>{ userAcct.presets_count ?? 0 } Presets</h3>
          <PresetsList presets={{items: userAcct.presets}} canDownload={false} />
        </section>
      </div>
    </Container>
  )
}