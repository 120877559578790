import React from 'react'
import './App.css'

// Routing
import routes from "../config/routes"
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import RouteWithSubRoutes from './routing/RouteWithSubRoutes'

// Child Views
import Header from './shared/Header'
import Footer from './shared/Footer'

// Navigation Links
import SignInOutNavLink from './navigation/SignInOutNavLink'
import PresetsNavLink from './navigation/PresetsNavLink'

// State
import { StateProvider, initialAppState } from '../state/AppState'
import appReducer from '../reducers/AppReducer'

function App() {
  return (
    <StateProvider initialState={initialAppState} reducer={appReducer}>
      <Router>
        <Header
          brand="Preset Swap"
          links={
            <>
              <PresetsNavLink />
              <SignInOutNavLink />
            </>
          }
        />
        <div id="app-container">
          <div id="content-container">
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    </StateProvider>
  );
}

export default App;
