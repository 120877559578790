import React, { useState, useEffect } from 'react'
import './PresetsList.css'

// UI
import { ListGroup } from 'react-bootstrap'
import PresetCell from './PresetCell'

// Requests
import { API_ROOT_V1 } from '../../config/api-config'

export default ({ preset }) => {
  const [recommendedPresets, setRecommendedPresets] = useState([])
  const [isFetchingPresets, setIsFetchingPresets] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!isFetchingPresets) {
      return
    }

    const fetchRecommendedPreset = async () => {
      const response = await fetch(`${API_ROOT_V1}/users/presets/recommended?preset_id=${preset.id}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      })
      const presetsJson = await response.json()
  
      setIsFetchingPresets(false)
      if (presetsJson && !presetsJson.error) {
        setRecommendedPresets(presetsJson)
      } else {
        console.log(presetsJson.error)
        setErrorMessage("Uh oh. An error occurred while fetching recommended presets.")
      }
    }

    fetchRecommendedPreset()
  }, [isFetchingPresets, preset.id])

  const renderPresetsList = (presets) => {
    if (!presets || presets.length === 0) {
      return <div className="no-presets">No Recommended Presets</div>
    } else {
      return presets.map((preset) => 
        <PresetCell 
          key={preset.id}
          canDownload={false}
          {...preset}
        />
      )
    }
  }

  return (
    <>
      <h3>You might like:</h3>

      <ListGroup>
        { renderPresetsList(recommendedPresets) }
      </ListGroup>

      {errorMessage && errorMessage.length > 0 && <div>{errorMessage}</div>}
    </>
  )
}