import React from 'react'
import AuthService from '../../services/auth/AuthService'
import { Redirect, Route } from 'react-router-dom'

export default ({ component: Component, ...rest }) => {  
  if (!AuthService.isLoggedIn()) {
    return <Redirect
      to={{
        pathname: '/signin'
      }}
    />
  }

  return <Route {...rest} render={rest.render} />
}
