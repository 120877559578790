import React, { useState }  from 'react'
import './Account.css'
import { useStateValue } from '../../state/AppState'
import { Tooltip } from 'react-bootstrap'
import UploadProfilePicModal from './UploadProfilePicModal';
import AccountImage from '../shared/account/AccountImage';

export default () => {
  const [{ user }] = useStateValue()
  const [isShowingPicModal, setIsShowingPicModal] = useState(false)

  const renderUploadProfilePicTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Click to upload a profile pic!
      </Tooltip>
    );
  }

  return (
    <>
      <div className="account-header">
        <AccountImage
          user={user}
          onClick={() => setIsShowingPicModal(true)}
          overlay={renderUploadProfilePicTooltip}
        />
        <div className="text-shadow">
          {user.fullName}
        </div>
      </div>

      <UploadProfilePicModal 
        show={isShowingPicModal}
        onHide={() => {
          if (isShowingPicModal) {
            setIsShowingPicModal(false)
          }
        }}
      />
    </>
  )
}