import React from 'react'
import { Nav } from 'react-bootstrap'
import './SignInNavLink.css'
import { LinkContainer } from 'react-router-bootstrap'

export default () => {
  return (
    <LinkContainer to="/signin">
      <Nav.Link eventKey="/signin">Sign in</Nav.Link>
    </LinkContainer>
  )
}
