import React from 'react'
import SignInNavLink from './SignInNavLink'
import { useStateValue } from '../../state/AppState'

export default () => {
const [{ user }] = useStateValue()

  return (
    (!user || !(user.apiKey ?? {}).value) && <SignInNavLink />
  )
}