import React, { useState, useEffect } from 'react'
import './UserCommPrefsForm.css'

// App State
import { useStateValue } from '../../state/AppState'
import { UPDATE_USER } from '../../reducers/UserReducer'

// Form
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, Col, Button, Spinner, InputGroup } from 'react-bootstrap'
import { API_ROOT_V1 } from '../../config/api-config'

const schema = yup.object({
  acceptsPresetCommentEmails: yup.bool().required()
})

export default () => {
  const [{ user }, dispatch] = useStateValue()
  const prefActive = (user.communicationPrefs?.items || []).find(pref => pref.name === "preset_comment_emails")?.is_active
  const initialFormState = {
    acceptsPresetCommentEmails: (prefActive === undefined) ? true : prefActive
  }
  const [userFormData, setUserFormData] = useState(initialFormState)
  const [isLoadingPrefs, setIsLoadingPrefs] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  
  useEffect(() => {
    if (!isLoadingPrefs) {
      return
    }

    const fetchPrefs = async () => {
      const userResponse = await fetch(`${API_ROOT_V1}/users/prefs`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${user.apiKey.value}`,
          'Accept': 'application/json'
        }
      })
      const responseJson = await userResponse.json()

      setIsLoadingPrefs(false)
      if (responseJson.error && responseJson.message) {
        setErrorMessage(responseJson.message)
      } else {
        dispatch({
          type: UPDATE_USER,
          user: user,
          communicationPrefs: responseJson
        })
      }
    }

    fetchPrefs()
  }, [isLoadingPrefs, dispatch, user.apiKey.value])

  useEffect(() => {
    if (!isLoading || Object.keys(userFormData).length === 0) {
      return
    }

    const reportUpdateCommentPref = () => {
      window.gtag('event', 'update_preset_comment_pref', {
        'event_category': 'engagement',
        'event_label': 'user'
      })
    }
    
    const updateUserRequest = async () => {
      const response = await fetch(`${API_ROOT_V1}/users/prefs`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${user.apiKey.value}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: "preset_comment_emails",
          is_active: userFormData.acceptsPresetCommentEmails
        })
      })
  
      setIsLoading(false)
      if (response.status !== 200) {
        const responseJson = await response.json()
        setErrorMessage(responseJson.reason || "An error occurred. Please try again.")
      } else {
        reportUpdateCommentPref()
        setIsLoadingPrefs(true)
      }
    }

    updateUserRequest()
  }, [isLoading, dispatch, user.apiKey.value, userFormData])

  return (
    <section id="userUrlKey">
      <div className="profile-title">Notification Preferences</div>

      <Form noValidate>
        <Form.Row>
          <Form.Group as={Col} controlId="acceptsPresetCommentEmails">
            <Form.Switch
              id="custom-switch"
              name="acceptsPresetCommentEmails"
              label="Send me emails when somone comments on my preset(s)"
              checked={userFormData.acceptsPresetCommentEmails}
              value={userFormData.acceptsPresetCommentEmails}
              onChange={() => {
                setErrorMessage('')
                setUserFormData({acceptsPresetCommentEmails: !userFormData.acceptsPresetCommentEmails})
                setIsLoading(true)
              }}
            />
          </Form.Group>
        </Form.Row>

        { errorMessage && errorMessage.length > 0 && <div className="error">{errorMessage}</div> }

        { isLoading ? (
          <Button variant="primary" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="sr-only">Loading...</span>
          </Button>
          ) : null
        }
      
      </Form>

    </section>
  )
}
