import React from 'react'
import './Privacy.css'
import { Container } from 'react-bootstrap'

export default () => {

  return (
    <Container>
      <div name="termly-embed" 
        data-id="f5e34658-0f50-4d87-a179-522207fd13f8"
        data-type="iframe"></div>
    
      { (function(d, s, id) {
        var js, tjs = d.getElementsByTagName(s)[0];
        console.log('termly id', tjs);
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://app.termly.io/embed-policy.min.js";
        tjs.parentNode.insertBefore(js, tjs);
      }(document, 'script', 'termly-jssdk')) }
    </Container>
  )
}