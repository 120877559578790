import React from 'react'
import './ConfirmDeletePresetModal.css'
import { Modal, Button } from 'react-bootstrap'

export default (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide} className="confirm-delete-preset-modal" animation={true}>
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Delete preset {props.preset.name}?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onCancel}>No</Button>
        <Button variant="danger" onClick={props.onConfirm}>Yes</Button>
      </Modal.Footer>
    </Modal>
  )
}