import React, { useState, useEffect } from 'react'
import './PresetCommentForm.css'
import { useStateValue } from '../../state/AppState'
import { Formik } from 'formik'
import * as yup from 'yup'
import { API_ROOT_V1 } from '../../config/api-config'
import { Form, InputGroup, Col, Button, Spinner } from 'react-bootstrap'

const initialFormState = { message: '' }

export default ({ preset, onComment }) => {
  const [{ user }] = useStateValue()
  const [errorMessage, setErrorMessage] = useState('')
  const [isSavingComment, setIsSavingComment] = useState(false)
  const [comment, setComment] = useState('')

  useEffect(() => {
    if (!comment || comment.length === 0) {
      return
    }

    setIsSavingComment(true)

    const reportCommentPreset = () => {
      window.gtag('event', 'comment_preset', {
        'event_category': 'engagement'
      })
    }

    const createCommentRequest = async () => {
      const response = await fetch(`${API_ROOT_V1}/users/presets/${preset.id}/comments`, {
        method: 'POST',
        // mode: 'cors',
        // credentials: 'include',
        body: JSON.stringify({
          message: comment
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.apiKey.value}`
        }
      })
      const responseJson = await response.json()

      setComment('')
      setIsSavingComment(false)

      if (responseJson.error && responseJson.reason) {
        setErrorMessage(responseJson.reason)
      } else {
        reportCommentPreset()
        onComment(responseJson)
      }
    }

    createCommentRequest()
  }, [comment, onComment, preset.id, user.apiKey.value])

  const schema = yup.object({
    message: yup.string().required()
  })

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(form) => {
        setErrorMessage('')
        setComment(form.message)
      }}
      initialValues={initialFormState}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="message">
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Enter a comment here..."
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  isInvalid={!!errors.message}
                />

                <div className="comment-submit">
                  { isSavingComment ? (
                    <Button variant="primary" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span>
                    </Button>
                  ) : (
                    <Button type="submit">Submit</Button>
                  )}
                </div>
                
                <Form.Control.Feedback type="invalid">
                  {errors.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>

          {errorMessage && errorMessage.length > 0 && <div className="error">Error: {errorMessage}</div>}
        
        </Form>
      )}
    </Formik>
  )
}