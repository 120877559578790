import React from 'react'
import './PresetCell.css'
import { ListGroupItem, Button, Container, Row, Col, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import download from '../../assets/images/download.svg'
import heart from '../../assets/images/heart.svg'

export default (props) => {

  const presetUrl = () => {
    return `/presets/${props.url_key}`
  }

  return (
    <ListGroupItem>
      <Container>
        <Row>
          <Col>
            <div className="preset-cell-name">
              <a href={presetUrl()}>{ props.name }</a>
            </div>
            <div className="preset-cell-details">
              <b>Plugin:</b> { props.plugin.manufacturer } – { props.plugin.name }<br />
              <b>{ props.user && 'User:' }</b> <Link to={`/users/${props.user && props.user.url_key}`}>
                { props.user && props.user.first_name } { props.user && props.user.last_name }<br />
              </Link>
              <div className="pill">
                <Image src={download} className="icon" /> {props.downloads_count ?? 0}
              </div>
              <div className="pill">
                <Image src={heart} className="icon" /> {props.likes_count ?? 0}
              </div>
            </div>
          </Col>
          <Col md="auto">
            <div className="preset-cell-actions">
              {props.canDownload && (
                <>
                  <Button 
                    size="sm"
                    variant="outline-success"
                    onClick={() => props.onDownload(props)}
                  >
                    Download
                  </Button>
                  <br />
                </>
              )}
              {props.canEdit && (
                <>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => props.onEdit(props)}
                  >
                    Edit
                  </Button>
                  <br />
                </>
              )}
              {props.canDelete && (
                <>
                  <Button
                    size="sm"
                    variant="outline-danger"
                    onClick={() => props.onDelete(props)}
                  >
                    Delete
                  </Button>
                  <br />
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </ListGroupItem>
  )
}