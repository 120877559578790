import React, { useState, useRef } from 'react'
import './UserDetailHeader.css'
import { useStateValue } from '../../state/AppState'
import { Container, Row, Col, Popover, Overlay } from 'react-bootstrap'
import AccountImage from '../shared/account/AccountImage'
import UserFollowButton from './UserFollowButton'
import UserDataService from '../../services/user/UserDataService'
import { useTimeout } from '../utility/useTimeout'

export default ({ user, onFollow }) => {
  const [{ user: loggedInUser }] = useStateValue()
  const [show, setShow] = useState(false)
  const followBtnRef = useRef()

  const handleClick = (isFollowed, event) => {
    setShow(!show)
    onFollow(isFollowed)
  }

  const followButton = (
    loggedInUser.id !== user.id && <UserFollowButton user={user} onFollow={handleClick} />
  )

  const showPopover = () => {
    followBtnRef.current = document.getElementById('follow-btn')
    setShow(true)
    setTimeout(UserDataService.setSawFollowUserPopover, 500)
  }

  const onHidePopover = () => {

  }

  const popover = (
    user.id && <Overlay
      placement="bottom"
      target={followBtnRef.current}
      rootClose={true}
      rootCloseEvent='click'
      show={show && !user.isFollowed && !UserDataService.hasSeenFollowUserPopover()}
      onHide={onHidePopover}
    >
      <Popover id="follow-popover">
        <Popover.Title as="h3">Follow for updates!</Popover.Title>
        <Popover.Content>
          Follow your favorites users to be notified when they upload new presets.
        </Popover.Content>
      </Popover>
    </Overlay>
  )

  return (
    <Container className="user-header">
      <Row className="text-shadow">
        <Col xl={3} lg={4} md={5} sm={3} xs={0}>
          <AccountImage user={user} picSizeClass='lg-pic' placeholderSizeClass='lg-placeholder' />
        </Col>
        <Col>
          <div>
            { user.first_name && user.last_name && 
              <h1>
                { user.first_name } { user.last_name }
              </h1>
            }
            <h3>presetswap.com/users/{user.url_key}</h3>
            { followButton }
            { useTimeout(showPopover, 2000) }
            { popover }
          </div>
        </Col>
      </Row>
    </Container>
  )
}