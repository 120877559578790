import AuthService from "../services/auth/AuthService"
import { initialAppState } from '../state/AppState'

// Multiple user actions
export const FETCH_USERS = 'FETCH_USERS'
export const RECEIVE_USERS = 'RECEIVE_USERS'

// Single user actions
export const FETCH_SINGLE_USER = 'FETCH_SINGLE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const RECEIVE_SINGLE_USER = 'RECEIVE_SINGLE_USER'
export const RECEIVE_USER_AVATAR = 'RECEIVE_USER_AVATAR'

// User Presets
export const RECEIVE_USER_PRESETS = 'RECEIVE_USER_PRESETS'
export const RECEIVE_USER_PRESET = 'RECEIVE_USER_PRESET'
export const DELETE_USER_PRESET = 'DELETE_USER_PRESET'

// Sign In/Out
export const SIGNOUT = "SIGNOUT"

const updateUser = (action, state) => {
  // console.log('updateUser::action', action)
  // console.log('updateUser::state', state)
  let { user } = action
  user = {
    ...state,
    ...user
  }
  // console.log('updateUser::user', user)
  const apiKey = user.api_key ?? user.apiKey
  const presets = action.presets ?? user.presets
  const communicationPrefs = action.communicationPrefs ?? user.communicationPrefs
  // console.log('updateUser::communicationPrefs', communicationPrefs)
  const newState = {
    apiKey: apiKey,
    avatar: user.avatar,
    id: user.id,
    urlKey: user.url_key || user.urlKey,
    email: user.email,
    createdAt: user.created_at || user.createdAt,
    updatedAt: user.updated_at || user.updatedAt,
    firstName: user.first_name || user.firstName,
    lastName: user.last_name || user.lastName,
    fullName: user.full_name || user.fullName,
    presets: presets || [],
    communicationPrefs: communicationPrefs || [],
    isFollowed: user.is_followed || user.is_followed
  }
  // console.log('updateUser::newState', newState)
  AuthService.loginUser(newState)

  return newState
}

export default function userReducer(state, action) {
  // console.log('userReducer::state', state)
  // console.log('userReducer::action', action)

  let newState

  switch (action.type) {
    case FETCH_SINGLE_USER: {
      newState = action
      break
    }
    case RECEIVE_SINGLE_USER: {
      newState = updateUser(action, state)
      break
    }
    case UPDATE_USER: {
      newState = updateUser(action, state)
      break
    }
    case RECEIVE_USER_AVATAR: {
        const user = state
      // console.log('RECEIVE_USER_AVATAR::user', user)
      user.avatar = action.avatar
      // console.log('RECEIVE_USER_AVATAR::user.avatar', user.avatar)
      newState = user
      AuthService.loginUser(newState)
      break
    }
    case RECEIVE_USER_PRESETS: {
      const user = state
      // console.log('RECEIVE_USER_PRESETS::user', user)
      user.presets = action.presets
      // console.log('RECEIVE_USER_PRESETS::user.presets', user.presets)
      newState = user
      AuthService.loginUser(newState)
      break
    }
    case RECEIVE_USER_PRESET: {
      const user = state
      // console.log('RECEIVE_USER_PRESET::user', user)
      if (user.presets.items && user.presets.items.some(preset => preset.id === action.preset.id)) {
        const i = user.presets.items.findIndex(preset => preset.id === action.preset.id)
        user.presets.items[i] = action.preset
      } else {
        user.presets.items = (user.presets.items || []).concat([action.preset])
        user.presets.metadata.total += 1
      }
      // console.log('RECEIVE_USER_PRESET::user.presets', user.presets)
      newState = user
      AuthService.loginUser(newState)
      break
    }
    case DELETE_USER_PRESET: {
      const user = state
      // console.log('DELETE_USER_PRESET::user', user)
      user.presets.items = user.presets.items.filter(preset => preset.id !== action.preset.id )
      user.presets.metadata.total -= 1
      // console.log('DELETE_USER_PRESET::user.presets', user.presets)
      newState = user
      AuthService.loginUser(newState)
      break
    }
    case SIGNOUT: {
      AuthService.logout()
      newState = initialAppState
      break
    }
    default:
      newState = state
  }

  // console.log('userReducer::newState', newState);
  
  return newState
}