  import React, { useEffect, useState } from 'react'
import { API_ROOT_V1 } from '../../config/api-config'
import PresetsList from './PresetsList'
import PresetSearchHeader from './PresetSearchHeader'
import { Container } from 'react-bootstrap'

export default () => {
  const [presets, setPresets] = useState([])
  const [searchCriteria, setSearchCriteria] = useState({})
  const [isLoadingPresets, setIsLoadingPresets] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!isLoadingPresets) {
      return
    }

    const fetchPresets = async () => {
      let url = `${API_ROOT_V1}/search/presets?per=100`
      if (searchCriteria.text) {
        url += `&name=${encodeURI(searchCriteria.text.replace(/[-||–||:]*/g,""))}`
      }
      if (searchCriteria.plugin) {
        const [manufacturer, plugin] = searchCriteria.plugin.split(' – ')
        url += `&plugin_manufacturer=${encodeURI(manufacturer.replace(/[-||–||:]*/g,""))}`
        url += `&plugin_name=${encodeURI(plugin.replace(/[-||–]*/g,""))}`
      }
      const response = await fetch(url, {
        method: 'GET',
        // mode: 'cors',
        // credentials: 'include',
        headers: {
          'Accept': 'application/json'
        }
      })
      const responsePresets = await response.json()
      setIsLoadingPresets(false)
      if (responsePresets.error && responsePresets.reason) {
        setErrorMessage(responsePresets.reason)
      } else {
        setPresets(responsePresets)
      }
    }

    fetchPresets()
  }, [isLoadingPresets, searchCriteria])

  const handleSearch = ({ text = null, plugin = null, sort = 'descending' }) => {
    setSearchCriteria({...searchCriteria, text, plugin, sort})
    setIsLoadingPresets(true)
  }

  return (
    <Container>
      <PresetSearchHeader onSearch={handleSearch} />
      <div className="body-wrapper">
        <PresetsList presets={presets} />
      </div>
    </Container>
  )
}