import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useStateValue } from '../../state/AppState'
import './SignIn.css'
import SignInForm from './SignInForm'

export default () => {
  const history = useHistory()
  const [{ user }] = useStateValue()

  useEffect(() => {
    user.apiKey && user.apiKey.value && history.push('/account')
  }, [user, history])

  return (
    <div id="signup-container">
      <SignInForm />
    </div>
  );
}