import React, { useState, useEffect } from 'react'
import './UserData.css'

// App State
import { useStateValue } from '../../state/AppState'
import { UPDATE_USER } from '../../reducers/UserReducer'

// Form
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, Col, Button, Spinner, InputGroup } from 'react-bootstrap'
import { API_ROOT_V1 } from '../../config/api-config'

const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  oldPassword: yup.string().optional(),
  newPassword: yup.string().optional()
})

export default () => {
  const [{ user }, dispatch] = useStateValue()
  const [userFormData, setUserFormData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const initialFormState = { 
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    oldPassword: '',
    newPassword: ''
  }

  useEffect(() => {
    if (!isLoading || Object.keys(userFormData).length === 0) {
      return
    }

    const updateUserRequest = async () => {
      let body = {
        email: userFormData.email,
        first_name: userFormData.firstName,
        last_name: userFormData.lastName
      }
  
      if (userFormData.oldPassword || userFormData.newPassword) {
        body = {
          old_password: userFormData.oldPassword,
          new_password: userFormData.newPassword,
          ...body
        }
      }
  
      const response = await fetch(`${API_ROOT_V1}/users/${user.id}`, {
        method: 'PATCH',
        // mode: 'cors',
        // credentials: 'include',
        headers: {
          'Authorization': `Bearer ${user.apiKey.value}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      const responseJson = await response.json()
  
      setIsLoading(false)
      if (responseJson.error && responseJson.reason) {
        setErrorMessage(responseJson.reason)
      } else {
        dispatch({
          type: UPDATE_USER,
          user: responseJson
        })
      }
    }

    updateUserRequest()
  }, [isLoading, dispatch, user.apiKey.value, user.id, userFormData])

  return (
    <section>
      <div className="profile-title">Profile Info</div>

      <Formik
        validationSchema={schema}
        onSubmit={(form, { resetForm }) => {
          setErrorMessage('')
          setUserFormData(form)
          setIsLoading(true)
        }}
        initialValues={initialFormState}
      >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} controlId="firstName">
              <Form.Label>First name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                placeholder="First name"
                value={values.firstName}
                onChange={handleChange}
                isValid={touched.firstName && !errors.firstName}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="lastName">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                placeholder="Last name"
                value={values.lastName}
                onChange={handleChange}
                isValid={touched.lastName && !errors.lastName}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="email">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="oldPassword">
              <Form.Label>Current Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  placeholder="Current Password"
                  name="oldPassword"
                  value={values.oldPassword}
                  onChange={handleChange}
                  isInvalid={!!errors.oldPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.oldPassword}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type="password"
                  placeholder="New Password"
                  name="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                  isInvalid={!!errors.newPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.newPassword}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>

          {errorMessage && errorMessage.length > 0 && <div className="error">{errorMessage}</div>}

          { isLoading ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="sr-only">Loading...</span>
            </Button>
           ) : (
            <Button variant="outline-primary" size="sm" type="submit">Update</Button>
           )
          }
        
        </Form>
      )}
      </Formik>
    </section>
  )
}
