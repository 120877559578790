import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import './PresetsList.css'

// App State
import { useStateValue } from '../../state/AppState'

// UI
import { ListGroup } from 'react-bootstrap'
import PresetCell from './PresetCell'
import ConfirmDeletePresetModal from './ConfirmDeletePresetModal'

// Requests
import { API_ROOT_V1 } from '../../config/api-config'
import { DELETE_USER_PRESET } from '../../reducers/UserReducer'
import PresetsInListAds from '../shared/ads/PresetsInListAds'

export default ({ presets, canDelete = false, canEdit = false, canDownload = true, showAds = true }) => {
  const [{ user }, dispatch] = useStateValue()
  const history = useHistory()
  const [deletePreset, setDeletePreset] = useState({})
  const [updatePreset, setUpdatePreset] = useState({})
  const [confirmDeletePreset, setConfirmDeletePreset] = useState(false)
  const [isDisplayingConfirmModal, setIsDisplayingConfirmModal] = useState(false)
  const [downloadPreset, setDownloadPreset] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!confirmDeletePreset) {
      return
    }

    const deletePresetRequest = async () => {
      const response = await fetch(`${API_ROOT_V1}/users/presets/${deletePreset.id}`, {
        method: 'DELETE',
        // mode: 'cors',
        // credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${user.apiKey.value}`
        }
      })
  
      setConfirmDeletePreset(false)
      setDeletePreset({})
      setIsDisplayingConfirmModal(false)
      if (response.status !== 200) {
        setErrorMessage("Unable to delete preset at the time. Please try again later.")
      } else {
        dispatch({
          type: DELETE_USER_PRESET,
          preset: deletePreset
        })
      }
    }

    deletePresetRequest()
  }, [confirmDeletePreset, deletePreset, dispatch, user.apiKey.value])

  useEffect(() => {
    if (!updatePreset || Object.keys(updatePreset).length === 0) {
      return
    }

    history.push(`/updatepreset/${updatePreset.url_key}`)
  }, [updatePreset, history])

  useEffect(() => {
    if (!deletePreset || Object.keys(deletePreset).length === 0) {
      return
    }

    setIsDisplayingConfirmModal(true)
  }, [deletePreset])

  useEffect(() => {
    if (!downloadPreset || Object.keys(downloadPreset).length === 0) {
      return
    }

    const fetchDownloadUrl = async () => {
      const response = await fetch(`${API_ROOT_V1}/users/presets/${downloadPreset.id}/downloads`, {
        method: 'POST',
        body: (user && user.id) ? JSON.stringify({ id: user.id}) : null,
        headers: {
          'Accept': 'application/json'
        }
      })
      const downloadJson = await response.json()
  
      setDownloadPreset({})
      if (downloadJson.url) {
        window.open(downloadJson.url, "_blank")
      } else {
        setErrorMessage("Unable to download preset at the time. Please try again later.")
      }
    }

    fetchDownloadUrl()
  }, [downloadPreset, user, user.apiKey.value])

  const renderPresetsList = (presets) => {
    if (!presets.items || presets.items.length === 0) {
      return <div className="no-presets">No Presets</div>
    } else {
      return presets.items.map((preset) => 
        <PresetCell 
          key={preset.id}
          canDelete={canDelete}
          onDelete={(preset) => { setDeletePreset(preset) }}
          canEdit={canEdit}
          onEdit={(preset) => { setUpdatePreset(preset) }}
          canDownload={canDownload}
          onDownload={(preset) => { setDownloadPreset(preset) }}
          {...preset}
        />
      )
    }
  }

  return (
    <>
      <ListGroup>
        { renderPresetsList(presets ?? user.presets) }
        { showAds && <PresetsInListAds key="in-list-ad" /> }
      </ListGroup>

      {errorMessage && errorMessage.length > 0 && alert(errorMessage)}

      <ConfirmDeletePresetModal
        show={isDisplayingConfirmModal}
        onHide={() => setIsDisplayingConfirmModal(false)}
        onCancel={() => setIsDisplayingConfirmModal(false)}
        onConfirm={() => setConfirmDeletePreset(true)}
        preset={deletePreset}
      />
    </>
  )
}