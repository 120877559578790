import React from 'react'
import './UserClip.css'
import { Image } from 'react-bootstrap'
import preset from '../../assets/images/preset.svg'
import download from '../../assets/images/download.svg'
import heart from '../../assets/images/heart.svg'
import AccountImage from '../shared/account/AccountImage'
import { Link } from 'react-router-dom'

export default ({ user }) => {

  const userUrl = () => {
    return `/users/${user.url_key}`
  }

  return (
    <div className="user-clip-wrapper">
      <div className="user-clip-image inline-block">
        <Link to={userUrl()}>
          <AccountImage user={user} picSizeClass='sm-pic' placeholderSizeClass='sm-placeholder' />
        </Link>
      </div>
      <div className="user-clip-name">
        <Link to={userUrl()}>
          { user.full_name }
        </Link>
      </div>
      <div className="user-clip-info">
        <div className="stat"><Image src={preset} className="icon" /> { user.presets_count ?? 0 }</div>
        <div className="stat"><Image src={download} className="icon" /> { user.downloads_count ?? 0 }</div>
        <div className="stat"><Image src={heart} className="icon" /> { user.likes_count ?? 0 }</div>
      </div>
    </div>
  )
}