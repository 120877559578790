import React from 'react'
import './PresetDetailBody.css'
import { Container, Row, Col, Button, Image, ListGroup, Popover, OverlayTrigger } from 'react-bootstrap';
import UserClip from '../account/UserClip';
import RightRailAds from '../shared/ads/RightRailAds'
import download from '../../assets/images/download.svg'
import heart from '../../assets/images/heart.svg'
import share from '../../assets/images/share.svg'
import PresetCommentForm from './PresetCommentForm'
import PresetLikeButton from './PresetLikeButton'
import TimeAgo from 'react-time-ago'
import RecommendedPresets from './RecommendedPresets';

export default ({ loggedInUser, preset, onDownload, onLike, onComment, onShare }) => {

  const sharePopover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Share this preset</Popover.Title>
      <Popover.Content>
        <p>
          Simply copy the link below and share wherever you'd like!
        </p>
        { `https://presetswap.com${window.location.pathname}` }
      </Popover.Content>
    </Popover>
  );
  
  const ShareButton = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={sharePopover}>
      <Button size="sm" className="btn" variant="outline-dark">
        <Image src={share} className="icon" /> Share
      </Button>
    </OverlayTrigger>
  );

  return (
    <div className="preset-body body-wrapper">
      <Container>
        <Row>
          <Col>
            <Container>
              <Row className="button-bar">
                <Col>
                  <Button size="sm" className="btn" variant="outline-dark" onClick={() => onDownload()}>
                    <Image src={download} className="icon" /> Download
                  </Button>

                  <PresetLikeButton 
                    preset={preset}
                    onLike={onLike}
                    isLiked={preset.is_liked}
                  />
                  
                  <ShareButton />
                </Col>
                <Col lg={2.5}>
                  <div className="pill">
                    <Image src={download} className="icon" /> {preset.downloads_count ?? 0}
                  </div>
                  <div className="pill">
                    <Image src={heart} className="icon" /> {preset.likes_count ?? 0}
                  </div>
                </Col>
              </Row>
              <Row className="divider-row">
                <Col className="divider-row">
                  <hr className="divider" />
                </Col>
              </Row>
              <Row>
                <Col lg={2}>
                  { preset.user && <UserClip user={preset.user} /> }
                </Col>
                <Col>
                  {
                    preset.youtube_id && (
                      <div className="preset-detail-wrapper">
                        <div className="preset-detail-title">
                          YouTube Demo Video
                        </div>
                        <div className="preset-filename">
                          <iframe title="youtube" id="ytplayer" type="text/html" width="100%" height="300"
 src={`https://www.youtube.com/embed/${preset.youtube_id}?autoplay=0&origin=https://presetswap.com`}
 frameborder="0"></iframe>
                        </div>
                      </div>
                    )
                  }
                  {
                    preset.soundcloud_id && (
                      <div className="preset-detail-wrapper">
                        <div className="preset-detail-title">
                          SoundCloud Audio Example
                        </div>
                        <div className="preset-filename">
                          <iframe title="soundcloud" width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${preset.soundcloud_id}&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=true`}></iframe>
                        </div>
                      </div>
                    )
                  }
                  <div className="preset-detail-wrapper">
                    <div className="preset-detail-title">
                      Filename
                    </div>
                    <div className="preset-filename">
                      { preset.filename }
                    </div>
                  </div>
                  <div className="preset-detail-wrapper">
                    <div className="preset-detail-title">
                      Notes
                    </div>
                    <div className="preset-notes">
                      { preset.notes }
                    </div>
                  </div>
                  <div className="preset-detail-wrapper">
                    <div className="preset-detail-title">
                      Comments
                    </div>
                    
                    { loggedInUser && loggedInUser.apiKey.value && (
                      <PresetCommentForm preset={preset} onComment={onComment} />
                    )}
                  
                    <ListGroup variant="flush">
                      { preset.comments && preset.comments.map(comment => (
                        <ListGroup.Item key={comment.id}>
                          <div className="user-name">{comment.user.first_name} {comment.user.last_name}</div>
                          <TimeAgo className="date" date={comment.created_at}/>
                          <br/>
                          <div className="comment">{comment.message}</div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col lg={4} md={0} sm={0} xs={0}>
            { preset.id && <RecommendedPresets preset={preset} /> }
            <RightRailAds />
          </Col>
        </Row>
      </Container>
    </div>
  )
}