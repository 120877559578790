import React from 'react'
import './Home.css'
import { Container, Button, Row, Col, Image } from 'react-bootstrap'
import neural from '../../assets/images/neuraldsp.png'
import stl from '../../assets/images/stltones.png'
import ml from '../../assets/images/mlsoundlab.png'
import heart from '../../assets/images/heart.svg'
import share from '../../assets/images/share.svg'
import comment from '../../assets/images/comment.svg'

export default () => {
    return (
      <div className="home-container">
        <div className="home-cta">
          <Container>
            <h1>Preset Swap</h1>
            <br/>
            <h2>Share your sound with the world! <span role="img" aria-label="Metal">🤘</span></h2>
            <p>
              Engage in an online community of fellow audio plugin enthusiasts by sharing your custom presets. 
              Sharing your presets allows others from around the world to download, like, share, and even leave you feedback and praise <span role="img" aria-label="praise">🙏</span> via comments.
            </p>
            <p>
              <Button href="/signup" size="lg" className="cta-button">Sign me up!</Button>
            </p>
          </Container>
        </div>

        <section>
          <Container id="how-it-works">
            <Row>
              <Col md="auto">
                <h2>How it works</h2>
                <p>
                  1. You create an awesome preset.
                </p>
                <p>
                  2. You upload the awesome preset.
                </p>
                <p>
                  3. You share the awesome preset on social media, with friends, etc.
                </p>
                <p>
                  4. Others download and benefit from your awesome preset! <span role="img" aria-label="celebrate">🎉</span>
                </p>
                <p>
                  <Button href="/uploadpreset" size="lg" className="cta-button">Upload a preset!</Button>
                </p>
              </Col>
              <Col>
                {/* <Image src={download} className="plugin-img" /><br/> */}
              </Col>
            </Row>
          </Container>
        </section>

        <section className="alt-section">
          <Container id="plugins-support">
            <Row>
              <Col>
                <Image src={neural} className="plugin-img" /><br/>
                <Image src={stl} className="plugin-img" /><br/>
                <Image src={ml} className="plugin-img" />
              </Col>
              <Col>
                <h2>Plugin support</h2>
                <p>
                  We support all the latest and greatest audio plugins like Neural DSP, ML Sound Lab, STLTones, and more. Plus, downloading and installing presets couldn't be easier!
                </p>
                <p>
                  <Button href="/presets" size="lg" className="cta-button">Search presets</Button>
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          <Container id="join-the-community">
            <Row className="justify-content-md-center">
              <h2>Join the community</h2>
            </Row>
            <Row>
              <Col>
              
                <Container>
                  <Row>
                    <Col sm={4}>
                      <Image src={heart} className="community-img" />
                    </Col>
                    <Col sm={8}>
                      <p>
                        <b>Like</b>
                      </p>
                      <p>
                        Show a preset creator some love by liking their preset.
                      </p>
                    </Col>
                  </Row>
                </Container>
              
              </Col>
              <Col>

                <Container>
                  <Row>
                    <Col sm={4}>
                      <Image src={comment} className="community-img" />
                    </Col>
                    <Col sm={8}>
                      <p>
                        <b>Comment</b>
                      </p>
                      <p>
                        Have feedback for a preset creator, or maybe just want to explain how awesome their preset is? Leave a comment!
                      </p>
                    </Col>
                  </Row>
                </Container>

              </Col>
              <Col>
 
                <Container>
                  <Row>
                    <Col sm={4}>
                      <Image src={share} className="community-img" />
                    </Col>
                    <Col sm={8}>
                      <p>
                        <b>Share</b>
                      </p>
                      <p>
                        If you truly love a preset, and think others would too, share it!
                      </p>
                    </Col>
                  </Row>
                </Container>

              </Col>
            </Row>  
          </Container>
        </section>
      </div>
    )
}