import userReducer from './UserReducer'
import pluginReducer from './PluginReducer'

export default ({ user, plugins }, action) => {
  // middleware goes here, i.e calling analytics service, etc.
  // console.log('appReducer::user', user)
  // console.log('appReducer::plugins', plugins)
  // console.log('appReducer::action', action)
  
  return {
    user: userReducer(user, action),
    plugins: pluginReducer(plugins, action)
  }
}